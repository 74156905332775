/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with jQuery, even when in .noConflict() mode.
 * ======================================================================== */

(function() {

  var un = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        /*
         * Mobile menu
         */
        jQuery('.header__nav-burger').click(function() {
           jQuery(this).toggleClass('active');
           jQuery('.header__nav').toggleClass('mobile-menu-active');
          });
        jQuery('.menu-item-has-children').click(function(){
            jQuery(this).children('.sub-menu').toggleClass('active');
          });

         /*
          * Ajax load more button init
          */
        jQuery('.loadmore').click(function() {
         var button = jQuery(this),
           data = {
           'action': 'loadmore',
           'query': loadmore_params.posts, // that's how we get params from wp_localize_script() function
           'page' : loadmore_params.current_page
         };

         jQuery.ajax({
           url : loadmore_params.ajaxurl, // AJAX handler
           data : data,
           type : 'POST',
           beforeSend : function() {
             button.text('Loading...'); // change the button text, you can also add a preloader image
           },
           success : function( data ) {
             if( data ) {
               jQuery('main').find('article:last-of-type').after( data ); // where to insert posts
               loadmore_params.current_page++;
               button.text( 'More posts' );

               if ( loadmore_params.current_page === loadmore_params.max_page ) {
                 button.remove(); // if last page, remove the button
               }

               // you can also fire the "post-load" event here if you use a plugin that requires it
               // jQuery( document.body ).trigger( 'post-load' );
             } else {
               button.remove(); // if no data, remove the button as well
             }
           }
         });
        });

      }

    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = un;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      jQuery.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  jQuery(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
